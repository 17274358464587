import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from '../../../config/axiosConfig';

import { Typography, IconButton, InputAdornment, Box, FormControlLabel, Checkbox } from '@mui/material';
import {
  CustomInputs,
  CustomButton,
  CustomTelInputs,
} from '../../../common/styles/customStyledComponents/customStyledComponents.jsx';

import { selectUser, setUser } from '../../../store/features/userSlice';
import { setPaymentsType } from '../../../store/features/toggleFormsSlice';
import { selectUserPrepopulateAddress } from '../../../store/features/userPrepopulateAddress';

import QuestionForm from '../QuestionForm.jsx';
import CustomSelectWithCurrency from '../../../utils/ui/SelectWithCurrencyComponent.jsx';
import AddressFields from '../../../utils/ui/AddressFieldsComponent.jsx';
import AddEmailForm from '../AddEmailForm.jsx';
import SuccessDialogTexts from './SuccessDialogTexts.js';
import FormTemplate from '../../../utils/ui/forms/FormTemplate.jsx';
import SnackbarComponent from '../../../utils/ui/SnackbarComponent.jsx';
import { useSnackbar } from '../../../utils/ui/js/snackbarUtils';
import { useMediaQueries } from '../../../utils/ui/js/useMediaQueries';
import { trimObjectValues } from '../../../utils/functions/trimObjectValues';
import { formatNumberWithTwoDecimals } from '../../../utils/functions/formatNumberWithTwoDecimals';
import { isEqualObjectsExceptIds } from '../../../utils/functions/isEqualObjects';
import performRefreshUser from '../../../utils/requests/refreshUser';
import LoadingIndicator from '../../../utils/ui/LoadingIndicator.jsx';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const PaymentForm = ({
  open,
  onClose,
  paymentType,
  productData,
  order,
  isOpenSuccessForm,
  isCloseForm,
  highestBid,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const userRef = useRef(user);
  const userPrepopulateAddress = useSelector(selectUserPrepopulateAddress);

  const { isSdScreen } = useMediaQueries();
  const [isBid, setIsBid] = useState(paymentType === 'bid');
  const [isBuyItNow, setIsBuyItNow] = useState(paymentType === 'buyNow' || paymentType === 'buy_now');
  const [isBuyTicket, setIsBuyTicket] = useState(paymentType === 'buyTicket' || paymentType === 'raffle');

  const buyNow = formatNumberWithTwoDecimals(productData?.converter?.buy_now);
  const ticketPrice = formatNumberWithTwoDecimals(productData?.converter?.price);
  const minimalBid = formatNumberWithTwoDecimals(productData?.converter?.minimum);
  const step = formatNumberWithTwoDecimals(productData?.converter?.step);
  const category = productData?.category?._id;
  const isHightestBidOrMinimal = highestBid !== 0 ? highestBid : minimalBid;
  const highestBidValue = formatNumberWithTwoDecimals(
    step ? isHightestBidOrMinimal + (highestBid !== 0 ? step : 0) : isHightestBidOrMinimal + (highestBid !== 0 ? 1 : 0)
  );
  const [bidPrice, setBidPrice] = useState(isBuyItNow ? buyNow : highestBidValue);

  const [userCurrency, setUserCurrency] = useState(user.currency);
  const [userAbbrName, setUserAbbrName] = useState(productData?.converter?.currency?.abbr_name);
  const [tickets, setTickets] = useState(1);
  const [totalPriceTickets, setTotalPriceTickets] = useState(tickets * ticketPrice);
  const [questionAnswer, setQuestionAnswer] = useState(null);
  const [questionId, setQuestionId] = useState(null);

  const [countriesList, setCountriesList] = useState([]);

  const [errorResponseMessage, setErrorResponseMessage] = useState('');
  const [bidPriceError, setBidPriceError] = useState('');

  const [loadingData, setLoadingData] = useState(false);
  const [isContinueBuy, setIsContinueBuy] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [openQuestionForm, setOpenQuestionForm] = useState(false);
  const [isDisabledBidButton, setIsDisabledBidButton] = useState(false);
  const [showAddEmailForm, setShowAddEmailForm] = useState(false);
  const [selectSameAddress, setSelectSameAddress] = useState(
    isEqualObjectsExceptIds(user?.shipping_address, user?.billing_address)
  );
  const selectSameAddressRef = useRef(selectSameAddress);

  const [billingAddress, setBillingAddress] = useState({
    country: user?.billing_address?.country,
    city: user?.billing_address?.city,
    street: user?.billing_address?.street,
    state: user?.billing_address?.state,
    postalCode: user?.billing_address?.postalCode,
  });
  const [shippingAddress, setShippingAddress] = useState({
    country: user?.shipping_address?.country,
    city: user?.shipping_address?.city,
    street: user?.shipping_address?.street,
    state: user?.shipping_address?.state,
    postalCode: user?.shipping_address?.postalCode,
  });
  const [buyerPhone, setBuyerPhone] = useState(user?.phone);

  const [billingErrors, setBillingErrors] = useState({
    country: '',
    city: '',
    street: '',
    state: '',
    postalCode: '',
  });
  const [shippingErrors, setShippingErrors] = useState({
    country: '',
    city: '',
    street: '',
    state: '',
    postalCode: '',
  });
  const [buyerPhoneError, setBuyerPhoneError] = useState('');

  const { isOpenSnackbar, snackbarMessage, snackbarSeverity, showSnackbar, closeSnackbar } = useSnackbar();
  const showSnackbarRef = useRef(showSnackbar);

  const handleBillingAddressChange = (field, value) => {
    setBillingAddress((prevAddress) => ({
      ...prevAddress,
      [field]: value,
    }));
    setBillingErrors((prevErrors) => ({
      ...prevErrors,
      [field]: '',
    }));
  };

  const handleShippingAddressChange = (field, value) => {
    setShippingAddress((prevAddress) => ({
      ...prevAddress,
      [field]: value,
    }));
    setShippingErrors((prevErrors) => ({
      ...prevErrors,
      [field]: '',
    }));
  };

  const handleConfirmBid = async () => {
    const fieldIsRequiredMsg = `${t('Field is required')}`;

    let hasError = false;

    const checkFields = (fields, setError) => {
      fields.forEach(({ value, fieldName }) => {
        if (!value || (typeof value === 'string' && !value.trim())) {
          setError((prevErrors) => ({
            ...prevErrors,
            [fieldName]: fieldIsRequiredMsg,
          }));
          hasError = true;
        }
      });
    };

    if (!buyerPhone) {
      setBuyerPhoneError(fieldIsRequiredMsg);
      hasError = true;
    }

    const billingFields = [
      { value: billingAddress.country, fieldName: 'country' },
      { value: billingAddress.city, fieldName: 'city' },
      { value: billingAddress.street, fieldName: 'street' },
      { value: billingAddress.postalCode, fieldName: 'postalCode' },
    ];
    checkFields(billingFields, setBillingErrors);

    if (!selectSameAddress) {
      const shippingFields = [
        { value: shippingAddress.country, fieldName: 'country' },
        { value: shippingAddress.city, fieldName: 'city' },
        { value: shippingAddress.street, fieldName: 'street' },
        { value: shippingAddress.postalCode, fieldName: 'postalCode' },
      ];
      checkFields(shippingFields, setShippingErrors);
    }

    if (hasError) {
      return;
    }
    try {
      setLoadingButton(true);
      const updatedUser = await axios.put('user/me', {
        firstName: user?.firstName,
        lastName: user?.lastName,
        phone: buyerPhone,
        shipping_address: selectSameAddress ? trimObjectValues(billingAddress) : trimObjectValues(shippingAddress),
        billing_address: trimObjectValues(billingAddress),
      });
      dispatch(setUser(updatedUser?.data));
      const order = await axios.post('order/create/' + productData._id, {
        buy_now: isBuyItNow ? true : false,
        amount: isBuyTicket ? tickets : 1,
        bid: isBid
          ? ((bidPrice / productData?.converter?.currency?.rate) * productData?.currency?.rate).toFixed(1)
          : null,
      });
      let delivery = {
        orderId: order.data?.id,
        deliveryService: [...productData?.deliveryService],
        addressFromId: productData.sellerAddressId,
        name: `${user?.firstName} ${user?.lastName}`,
        phone: buyerPhone,
        parcelId: productData.parcelId,
      };
      Object.assign(delivery, selectSameAddress ? trimObjectValues(billingAddress) : trimObjectValues(shippingAddress));
      await axios.post('delivery', delivery);
      if (questionId && questionAnswer)
        await axios.post('quiz/answer', {
          orderId: order.data?.id,
          question: questionId,
          answer: questionAnswer,
        });
      onClose();
      dispatch(setPaymentsType({ isPaymentOpened: false, paymentType: null }));
      setLoadingButton(false);
      window.location.assign(order.data.url);
    } catch (error) {
      if (error.response?.data?.status === 205) {
        const respObj = JSON.parse(error.response?.data?.response);
        selectSameAddress
          ? setBillingAddress(respObj.recommendedAddress)
          : setShippingAddress(respObj.recommendedAddress);
        showSnackbar({ response: { data: { message: respObj.message } } });
      } else {
        showSnackbar(error);
        if (error.response && error.response.status === 403)
          if (error?.response?.data?.message === 'You cannot enter your draw')
            setErrorResponseMessage(error.response.data.message);
          else setShowAddEmailForm(true);
      }
      setLoadingButton(false);
    }
  };

  const handleTicketsChange = (increment) => {
    const newTickets = tickets + increment;
    if (newTickets >= 1 && newTickets <= productData?.productInfo?.amount) {
      const newTotalTickets = newTickets * ticketPrice;
      setTickets(newTickets);
      setTotalPriceTickets(newTotalTickets);
    }
  };
  const handleStepChange = (increment) => {
    const newValue = formatNumberWithTwoDecimals(bidPrice + increment);
    if (newValue >= highestBidValue) {
      setBidPrice(newValue);
    }
  };

  const handleBid = async () => {
    if (isBid) {
      if (!bidPrice && !highestBidValue) {
        setBidPriceError(`${t('Field is required')}`);
        return;
      }
    }
    isBuyTicket ? setOpenQuestionForm(true) : setIsContinueBuy(true);
  };

  const handleCloseSuccessForm = () => {
    const currentUrl = new URL(window.location.href);
    const baseUrl = currentUrl.origin + currentUrl.pathname;
    window.history.replaceState(null, null, baseUrl);
    isCloseForm();
  };

  const handleCloseForm = () => {
    if (onClose) {
      onClose();
      setIsContinueBuy(false);
      setOpenQuestionForm(false);
      setBidPriceError('');
      setBidPrice(null);
      setErrorResponseMessage('');
    }
  };

  useEffect(() => {
    setTotalPriceTickets(formatNumberWithTwoDecimals(tickets * ticketPrice));
  }, [tickets, ticketPrice]);

  useEffect(() => {
    setBidPrice(isBuyItNow ? buyNow : highestBidValue);
  }, [isBuyItNow, buyNow, highestBidValue, open]);

  useEffect(() => {
    setIsBid(paymentType === 'bid');
    setIsBuyItNow(paymentType === 'buyNow' || paymentType === 'buy_now');
    setIsBuyTicket(paymentType === 'buyTicket' || paymentType === 'raffle');
  }, [open, paymentType]);

  const handleFetchData = useCallback(async () => {
    if (isContinueBuy) {
      try {
        setLoadingData(true);
        const countriesListResponse = await axios.get('country');
        await performRefreshUser(dispatch);
        setCountriesList(countriesListResponse?.data);
        const isSameCountry = userPrepopulateAddress?.country === userRef.current?.country?.iso;
        const isUserCountry = userRef.current?.country;
        if (!userRef.current.billing_address) {
          setBillingAddress((prevBillingAddress) => ({
            ...prevBillingAddress,
            country: isUserCountry ? userRef.current.country.iso : userPrepopulateAddress?.country,
            city: !isUserCountry || isSameCountry ? userPrepopulateAddress?.city : '',
            postalCode: !isUserCountry || isSameCountry ? userPrepopulateAddress?.postalCode : '',
          }));
          if (!selectSameAddressRef.current) {
            setShippingAddress((prevShippingAddress) => ({
              ...prevShippingAddress,
              country: isUserCountry ? userRef.current.country.iso : userPrepopulateAddress?.country,
              city: !isUserCountry || isSameCountry ? userPrepopulateAddress?.city : '',
              postalCode: !isUserCountry || isSameCountry ? userPrepopulateAddress?.postalCode : '',
            }));
          }
        }
        setLoadingData(false);
      } catch (error) {
        showSnackbarRef.current(error);
      }
    }
  }, [
    dispatch,
    isContinueBuy,
    userPrepopulateAddress?.city,
    userPrepopulateAddress?.country,
    userPrepopulateAddress?.postalCode,
  ]);

  useEffect(() => {
    if (open) {
      handleFetchData();
    }
  }, [handleFetchData, open]);

  useEffect(() => {
    userRef.current = user;
    setUserCurrency(user.currency);
    setUserAbbrName(productData?.converter?.currency?.abbr_name);
    setBillingAddress({
      country: user?.billing_address?.country,
      city: user?.billing_address?.city,
      street: user?.billing_address?.street,
      state: user?.billing_address?.state,
      postalCode: user?.billing_address?.postalCode,
    });
    setShippingAddress({
      country: user?.shipping_address?.country,
      city: user?.shipping_address?.city,
      street: user?.shipping_address?.street,
      state: user?.shipping_address?.state,
      postalCode: user?.shipping_address?.postalCode,
    });
    setBuyerPhone(user?.phone);
  }, [user, open, productData?.converter?.currency?.abbr_name]);

  // dialog scroll reset between form states
  const dialogTop = useRef(null);
  const scrollToTop = () => {
    if (dialogTop.current)
      setTimeout(() => {
        dialogTop.current.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'nearest' });
      }, 100);
  };
  useEffect(() => {
    if (openQuestionForm !== undefined) scrollToTop();
  }, [openQuestionForm]);

  return (
    <>
      <FormTemplate
        scrollElRef={dialogTop}
        open={open}
        onClose={handleCloseForm}
        dialogTitle={
          <>
            <div ref={dialogTop} style={{ position: 'absolute', top: 0 }}></div>
            <Typography fontSize={18} align="center">
              {isBid
                ? `${t('Enter the amount you would like to bid')}.`
                : isBuyItNow
                  ? `${t('Buy it now for')}:`
                  : `${t('Buy Ticket')}`}
            </Typography>
          </>
        }
        dialogContent={
          <>
            {!isBuyTicket ? (
              <>
                <CustomSelectWithCurrency
                  placeholder="Enter Amount"
                  type="number"
                  sx={{ width: '100%', mt: 1 }}
                  handleChange={setBidPrice}
                  error={bidPriceError}
                  setError={setBidPriceError}
                  selectedCurrency={userCurrency}
                  value={isBuyItNow ? buyNow : bidPrice}
                  minimalBid={minimalBid ? highestBidValue : null}
                  setIsDisabledBidButton={setIsDisabledBidButton}
                  step={step && !isBuyItNow ? step : null}
                  handleAddStep={() => {
                    handleStepChange(step);
                  }}
                  handleSubtractStep={() => {
                    handleStepChange(-step);
                  }}
                  isDisabledSelectedCurrency={true}
                  currencyAbrName={userAbbrName}
                  isReadOnly={step || isBuyItNow}
                />
                {step !== 0 && isBid && (
                  <>
                    <Typography mt={2} fontSize={14} align="center" className="flex items-center">
                      <InfoOutlinedIcon />
                      <span>{t(`Seller has set minimum raise ${step}`)}</span>
                    </Typography>
                  </>
                )}
              </>
            ) : (
              <>
                <CustomInputs
                  value={tickets}
                  variant="outlined"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  sx={{ mt: 1, width: '100%' }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ color: 'black' }}>
                        {t('Quantity')}
                        <Box
                          sx={{
                            height: 40,
                            borderLeft: '1px solid #00000080',
                            ml: isSdScreen ? 2 : 1,
                            mr: 1,
                          }}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <>
                        <IconButton onClick={() => handleTicketsChange(1)} sx={{ padding: isSdScreen ? null : 0 }}>
                          <AddRoundedIcon
                            style={{
                              fontSize: isSdScreen ? '24px' : '20px',
                              color: 'black',
                            }}
                          />
                        </IconButton>
                        <IconButton onClick={() => handleTicketsChange(-1)} sx={{ padding: isSdScreen ? null : 0 }}>
                          <RemoveRoundedIcon style={{ fontSize: '24px', color: 'black' }} />
                        </IconButton>
                      </>
                    ),
                    readOnly: true,
                  }}
                />
                <CustomInputs
                  value={totalPriceTickets}
                  variant="outlined"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  sx={{ width: '100%', mt: 4 }}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start" sx={{ color: 'black' }}>
                        {t(`Total ${user.abbr_name}`)}
                        <Box
                          sx={{
                            height: 40,
                            borderLeft: '1px solid #00000080',
                            ml: 2,
                            mr: 1,
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            )}
            {isContinueBuy && (
              <>
                <Typography sx={{ mt: 2 }} fontSize={20} color="#00000080">
                  {t('Add Billing Address')}
                </Typography>
                {isBid && (
                  <Typography fontSize={19} textAlign={'center'}>
                    {t(
                      `Your bid will be lead until it will be outbid by another user. Once outbid - we will notify you via email`
                    )}
                    !
                  </Typography>
                )}
                <div>
                  <AddressFields
                    values={billingAddress}
                    onChange={handleBillingAddressChange}
                    errors={billingErrors}
                    countriesList={countriesList}
                  />
                  <FormControlLabel
                    label="Shipping address same as Billing address"
                    control={
                      <Checkbox
                        checked={selectSameAddress}
                        onChange={(e) => {
                          setSelectSameAddress(e.target.checked);
                        }}
                      />
                    }
                  />
                  {!selectSameAddress && (
                    <>
                      <Typography sx={{ mt: 2 }} className="text-center" fontSize={20} color="#00000080">
                        {t('Add Shipping Address')}
                      </Typography>
                      <AddressFields
                        values={shippingAddress}
                        onChange={handleShippingAddressChange}
                        errors={shippingErrors}
                        countriesList={countriesList}
                      />
                    </>
                  )}
                </div>
                <CustomTelInputs
                  value={buyerPhone}
                  placeholder={t('Phone no. with country code')}
                  variant="outlined"
                  defaultCountry={null}
                  sx={{ mt: 3 }}
                  style={{ width: '85%' }}
                  onChange={(phone) => {
                    setBuyerPhone(phone);
                    setBuyerPhoneError('');
                  }}
                  helperText={buyerPhoneError ? buyerPhoneError : ' '}
                  error={!!buyerPhoneError}
                />
              </>
            )}
          </>
        }
        dialogActions={
          <>
            <CustomButton
              variant="contained"
              sx={{ px: 6 }}
              onClick={isContinueBuy ? handleConfirmBid : handleBid}
              disabled={
                loadingButton || loadingData
                  ? true
                  : isBuyTicket
                    ? null
                    : isBuyItNow
                      ? !buyNow
                      : (!bidPrice && !highestBidValue) || isDisabledBidButton
              }
            >
              <LoadingIndicator
                isLoading={loadingButton || loadingData}
                value={
                  <>
                    <div style={{ flex: 1 }}>
                      {isBid ? (
                        <>{isContinueBuy ? `${t('confirm bid')}` : `${t('bid')}`}</>
                      ) : isBuyItNow ? (
                        <>{isContinueBuy ? `${t('buy')}` : `${t('buy')}`}</>
                      ) : (
                        <>{isContinueBuy ? `${t('buy ticket')}` : `${t('continue')}`}</>
                      )}
                    </div>
                  </>
                }
              />
            </CustomButton>
            <div className="mt-2 text-red-600">{errorResponseMessage && errorResponseMessage}</div>
          </>
        }
      />
      {isBuyTicket && (
        <QuestionForm
          open={openQuestionForm}
          onClose={handleCloseForm}
          onClick={(questionId, questionAnswer) => {
            setIsContinueBuy(true);
            setOpenQuestionForm(false);
            setQuestionId(questionId);
            setQuestionAnswer(questionAnswer);
          }}
          category={category}
        />
      )}
      <div>
        {isOpenSuccessForm && (
          <>
            <FormTemplate
              open={isOpenSuccessForm}
              onClose={handleCloseSuccessForm}
              dialogTitle={
                <Typography align="center" fontSize={32}>
                  {SuccessDialogTexts({ isBid, isBuyItNow, t }).title}
                </Typography>
              }
              dialogContent={
                <Typography align="center" fontSize={20}>
                  {SuccessDialogTexts({ isBid, isBuyItNow, t, order }).content}
                </Typography>
              }
            />
          </>
        )}
      </div>
      {showAddEmailForm && <AddEmailForm onOpen={true} onClose={setShowAddEmailForm(false)} />}
      <SnackbarComponent
        open={isOpenSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default PaymentForm;
