import React, { useState, useCallback, useEffect, useRef } from 'react';
import axios from '../../config/axiosConfig';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setAccessToken, setRefreshToken } from '../../store/features/authSlice';
import { setUser, setUserCurrency } from '../../store/features/userSlice';
import { setPreviousUrl, selectPreviousUrl } from '../../store/features/urlSlice';
import { setUserPrepopulateAddress } from '../../store/features/userPrepopulateAddress';
import { useMediaQueries } from '../../utils/ui/js/useMediaQueries';
import SnackbarComponent from '../../utils/ui/SnackbarComponent.jsx';
import { useSnackbar } from '../../utils/ui/js/snackbarUtils';
import { baseURL, countriesBanList } from '../../utils/constants';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import { CustomInputs, CustomButton } from '../../common/styles/customStyledComponents/customStyledComponents.jsx';
import LoadingIndicator from '../../utils/ui/LoadingIndicator.jsx';
import ForgotPassword from './ForgotPassword.jsx';
import EmailForm from './EmailForm.jsx';
import SignUpForm from './singup/SignUpForm.jsx';

import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import googleIcon from '../../assets/icons/googleIcon.svg';
import facebookIcon from '../../assets/icons/facebookIcon.svg';
import appleIcon from '../../assets/icons/appleIcon.svg';

const LoginForm = ({ open, onClose, setLoading, isNeedToLoader }) => {
  const { t } = useTranslation();
  const { isSdScreen, isSgScreen, isMdScreen, isSgMaxScreen } = useMediaQueries();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hash } = useParams();
  const { isOpenSnackbar, snackbarMessage, snackbarSeverity, showSnackbar, closeSnackbar } = useSnackbar();
  const showSnackbarRef = useRef(showSnackbar);
  const [email, setEmail] = useState(hash ? JSON.parse(atob(hash.split('.')[1])).email : '');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [forgotPassword, setForgotPassword] = useState(false);
  const [continueWithEmail, setContinueWithEmail] = useState(hash ? true : false);
  const [unverifiedUser, setUnverifiedUser] = useState(false);
  const [isUserBanned, setIsUserBanned] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [signUpForm, setSignUpForm] = useState(false);
  const [isLoadingFetching, setIsLoadingFetching] = useState(true);
  const [loginLoading, setLoginLoading] = useState(false);

  const previousUrl = useSelector(selectPreviousUrl);

  const handleLogin = async () => {
    let hasError = false;
    if (!email) {
      setEmailError(`${t('Email address is required')}`);
      setContinueWithEmail(false);
      hasError = true;
    }
    if (!password) {
      setPasswordError(`${t('Password is required')}`);
      hasError = true;
    }
    if (hasError) {
      return;
    }

    setLoginLoading(true);
    axios
      .post('auth/sign-in', {
        email: email.trim(),
        password: password.trim(),
      })
      .then(function (response) {
        dispatch(setUser(response.data.user));
        dispatch(setAccessToken(response.data.auth.accessToken));
        dispatch(setRefreshToken(response.data.auth.refreshToken));
        if (!response.data.user?.currency) {
          dispatch(
            setUserCurrency(
              process.env.REACT_APP_NODE_ENV === 'production' ? '6606ac954fbf9c7941c5e7fb' : '65158717bb30bd020a1ca060'
            )
          );
        }
        setLoginLoading(false);
        if (previousUrl) {
          if (previousUrl === '/login') {
            navigate(-4);
          } else {
            if (previousUrl === window.location.pathname) {
              handleCloseForm();
            } else {
              navigate(previousUrl);
            }
          }
          dispatch(setPreviousUrl(null));
        } else {
          handleCloseForm();
        }
      })
      .catch(function (error) {
        setPasswordError(error?.response?.data?.message);
        if (error?.response?.status === 403) {
          setUnverifiedUser(true);
        }
      });
  };

  const forgotPasswordError = (error) => {
    setForgotPassword(false);
    setContinueWithEmail(false);
    setEmailError(error);
  };

  const isValidEmail = (email) => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailPattern.test(email);
  };

  const handeContinueWithEmail = () => {
    if (!email) {
      setEmailError(`${t('Email address is required')}`);
    } else if (!isValidEmail(email)) {
      setEmailError(`${t('Please enter a valid email address')}`);
    } else {
      setEmailError('');
      setContinueWithEmail(true);
    }
  };

  const handleGoBackForm = () => {
    if (continueWithEmail) {
      setContinueWithEmail(false);
    }
    if (forgotPassword) {
      setForgotPassword(false);
      setContinueWithEmail(true);
    }
  };

  const handleCloseForm = () => {
    if (onClose) {
      onClose();
    }
    if (previousUrl !== window.location.pathname || window.location.pathname.startsWith('/email-verification/')) {
      navigate('/');
    }
  };

  const handleFetchData = useCallback(async () => {
    try {
      setLoading(true);
      setIsLoadingFetching(true);
      if (isUserBanned === null) {
        const prepopulateAddressResponse = await axios.get('https://ipapi.co/json');
        const { data } = prepopulateAddressResponse;
        if (data) {
          dispatch(
            setUserPrepopulateAddress({
              country: prepopulateAddressResponse.data?.country_name,
              city: prepopulateAddressResponse.data?.city,
              postalCode: prepopulateAddressResponse.data?.postal,
              continentCode: prepopulateAddressResponse.data?.continent_code,
              currency: prepopulateAddressResponse.data?.currency,
            })
          );
          const [countryName, countryCode, countryCodeIso3] = [
            prepopulateAddressResponse.data?.country_name,
            prepopulateAddressResponse.data?.country,
            prepopulateAddressResponse.data?.country_code_iso3,
          ];
          const isRestrictedCountry = Object.values(countriesBanList).some(
            ([name, code, iso3]) => countryName === name || countryCode === code || countryCodeIso3 === iso3
          );
          if (isRestrictedCountry) {
            setIsUserBanned(true);
            navigate('/you-banned');
          }
        } else {
          setLoading(false);
          setIsLoadingFetching(false);
          return;
        }
      } else {
        navigate('/you-banned');
      }
      setLoading(false);
      setIsLoadingFetching(false);
    } catch (error) {
      showSnackbarRef.current(error);
      setLoading(false);
      setIsLoadingFetching(false);
    }
  }, [dispatch, navigate, isUserBanned, setLoading]);

  useEffect(() => {
    handleFetchData();
  }, [handleFetchData, open]);
  return (
    <>
      <Dialog
        open={open && !unverifiedUser && !isUserBanned && (isNeedToLoader ? !isLoadingFetching : true)}
        onClose={onClose}
      >
        <div style={{ minWidth: isMdScreen ? 580 : 200 }}>
          {isLoadingFetching ? (
            <>
              <div
                className="mt-6 flex justify-center items-center"
                style={{
                  minHeight: 718,
                  minWidth: isSgScreen ? 352 : isSdScreen ? 310 : 256,
                }}
              >
                <CircularProgress color="inherit" />
              </div>
            </>
          ) : (
            <>
              {!signUpForm ? (
                <>
                  {!forgotPassword ? (
                    <>
                      <DialogTitle
                        sx={{
                          pb: isSgMaxScreen ? 1 : null,
                        }}
                      >
                        <div className="flex justify-between items-center">
                          {continueWithEmail ? (
                            <IconButton onClick={handleGoBackForm}>
                              <ArrowBackIosRoundedIcon style={{ fontSize: '26px', color: 'black' }} />
                            </IconButton>
                          ) : (
                            <div></div>
                          )}
                          <IconButton onClick={handleCloseForm}>
                            <ClearRoundedIcon style={{ fontSize: '32px', color: 'black' }} />
                          </IconButton>
                        </div>
                        <Typography
                          component="div"
                          variant={`h${isSgMaxScreen ? 5 : 4}`}
                          align="center"
                          style={
                            isSgMaxScreen
                              ? {
                                  position: 'absolute',
                                  left: 'calc(50% - 32px)',
                                  top: 24,
                                }
                              : null
                          }
                        >
                          {t('Log In')}
                        </Typography>
                      </DialogTitle>
                      {!continueWithEmail ? (
                        <>
                          <DialogContent
                            sx={{
                              mt: isSgMaxScreen ? 0 : 3,
                              pb: isSgMaxScreen ? 0 : null,
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                          >
                            <CustomInputs
                              value={email}
                              name="email"
                              placeholder={t('Email address')}
                              variant="outlined"
                              required
                              type="email"
                              sx={{
                                mt: isSgMaxScreen ? 0 : 1,
                              }}
                              InputProps={{
                                type: 'email',
                                name: 'email',
                              }}
                              onChange={(e) => {
                                setEmail(e.target.value);
                                setEmailError('');
                              }}
                              helperText={emailError ? emailError : ' '}
                              error={!!emailError}
                            >
                              <input type="text" />
                            </CustomInputs>
                          </DialogContent>
                          <DialogActions
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              mx: isSgMaxScreen ? 0 : 20,
                              pt: isSgMaxScreen ? 0 : null,
                            }}
                          >
                            <CustomButton
                              variant="contained"
                              onClick={handeContinueWithEmail}
                              disabled={!email}
                              fontSize={isSgMaxScreen ? '1rem' : null}
                            >
                              {t('Continue with Email')}
                            </CustomButton>
                            <Typography variant="h6" align="center" mt={isSgMaxScreen ? 1 : 2} fontWeight={300}>
                              or
                            </Typography>
                            <div className={`mt-${isSgMaxScreen ? 2 : 4} flex flex-col items-center mr-2`}>
                              <Link to={baseURL + 'auth/google'}>
                                <CustomButton variant="contained" fontSize={isSgMaxScreen ? '0.9rem' : null}>
                                  <img
                                    src={googleIcon}
                                    alt="Google Icon"
                                    style={{ width: `3${isSgMaxScreen ? 0 : 2}px` }}
                                  />
                                  <div style={{ flex: 1 }}>{t('Continue with Google')}</div>
                                </CustomButton>
                              </Link>
                              {/* <Link to={baseURL + 'auth/facebook'}>
                                <CustomButton
                                  variant="contained"
                                  fontSize={isSgMaxScreen ? '0.9rem' : null}
                                  sx={{ mt: isSgMaxScreen ? 1 : 2 }}
                                >
                                  <img
                                    src={facebookIcon}
                                    alt="Facebook Icon"
                                    style={{ width: `3${isSgMaxScreen ? 0 : 2}px` }}
                                  />
                                  <div style={{ flex: 1 }}>{t('Continue with Facebook')}</div>
                                </CustomButton>
                              </Link> */}
                              <Link to={baseURL + 'auth/apple'}>
                                <CustomButton
                                  variant="contained"
                                  fontSize={isSgMaxScreen ? '0.9rem' : null}
                                  sx={{ mt: isSgMaxScreen ? 1 : 2 }}
                                >
                                  <img
                                    src={appleIcon}
                                    alt="Apple Icon"
                                    style={{ width: `3${isSgMaxScreen ? 0 : 2}px` }}
                                  />
                                  <div style={{ flex: 1 }}>{t('Continue with Apple')}</div>
                                </CustomButton>
                              </Link>
                              <Typography variant="h6" align="center" fontWeight={300} mt={isSgMaxScreen ? 1 : 4}>
                                {t("Don't have an account")}?{' '}
                              </Typography>
                            </div>
                          </DialogActions>
                          <div className={`flex justify-center mb-10 mt-${isSgMaxScreen ? 1 : 2}`}>
                            <CustomButton
                              variant="contained"
                              fontSize={isSgMaxScreen ? '1rem' : null}
                              onClick={() => setSignUpForm(true)}
                            >
                              {t('Sign Up')}
                            </CustomButton>
                          </div>
                        </>
                      ) : (
                        <>
                          <DialogContent
                            sx={{
                              mt: 3,
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                          >
                            <CustomInputs
                              placeholder={t('Password')}
                              variant="outlined"
                              type={showPassword ? 'text' : 'password'}
                              required
                              sx={{ mt: 1 }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() => setShowPassword(!showPassword)}
                                      edge="end"
                                    >
                                      {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              value={password}
                              onChange={(e) => {
                                setPassword(e.target.value);
                                setPasswordError('');
                              }}
                              helperText={passwordError ? passwordError : ' '}
                              error={!!passwordError}
                            />
                            <button
                              onClick={() => setForgotPassword(true)}
                              className="mt-4 underline"
                              style={{ fontSize: '20px' }}
                            >
                              {t('Forgot Password')}?
                            </button>
                          </DialogContent>
                          <DialogActions
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              pt: 2,
                              pb: 6,
                              mx: isSgMaxScreen ? 0 : 20,
                            }}
                          >
                            <CustomButton
                              sx={{ width: isSgMaxScreen ? null : 250 }}
                              variant="contained"
                              onClick={handleLogin}
                              disabled={loginLoading}
                            >
                              <LoadingIndicator
                                isLoading={loginLoading}
                                value={<div style={{ flex: 1 }}>{t('Log In')}</div>}
                              />
                            </CustomButton>
                          </DialogActions>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <ForgotPassword
                        open={true}
                        onClose={handleCloseForm}
                        onError={forgotPasswordError}
                        email={email}
                        goBack={handleGoBackForm}
                      />
                    </>
                  )}
                </>
              ) : (
                <>
                  <SignUpForm open={true} onClose={handleCloseForm} goBack={() => setSignUpForm(false)} />
                </>
              )}
            </>
          )}
        </div>
      </Dialog>
      <EmailForm
        open={unverifiedUser}
        onClose={handleCloseForm}
        email={email}
        isUnverifiedUser={true}
        title={t('Please verify your email to continue')}
        addionalText={t(
          "Please check your email for message with verification link. Don't forget to check Spam folder. If you can't find our message we can resend you the link"
        )}
      />
      <SnackbarComponent
        open={isOpenSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default LoginForm;
